import styles from "./MainContent.module.css";
import Grid from "@mui/material/Grid2";
import {Card, CardContent, CardHeader, Collapse, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, {useState} from "react";
import {ExpandMore} from "@mui/icons-material";

const RelatedArticles = ({isLoading, articles}) => {
    const [expandedFaq, setExpandedFaq] = useState();

    return <Grid container spacing={2}>
        {/* Loading Indicator for FAQs */}
        {isLoading && <p className={styles.loading}>...</p>}

        {/* Related FAQ Articles */}
        {!isLoading && articles.length > 0 && (<Grid columns={2}>
            {articles.map((faq, index) => <Card size={4} key={index}>
                <CardHeader subheader={faq.question}
                            sx={{cursor: 'pointer', fontSize: "small"}}
                            onClick={() => setExpandedFaq(index === expandedFaq ? null : index)}
                            action={<ExpandMore
                                color="primary"
                                expand={index === expandedFaq}
                                aria-expanded={index === expandedFaq}
                                aria-label="show more">
                                <ExpandMoreIcon color="primary"/>
                            </ExpandMore>}
                />
                <Collapse in={index === expandedFaq}>
                    <CardContent sx={{textAlign: 'left'}}>
                        <Typography>{faq.answer}</Typography>
                    </CardContent>
                </Collapse>
            </Card>)}
        </Grid>)}
    </Grid>
}

export default RelatedArticles;