// theme.js
import { createTheme } from '@mui/material/styles';

// Define your theme here
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Custom primary color
    },
    secondary: {
      main: '#dc004e', // Custom secondary color
    },
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          border: 0
        }
      }
    }
  }
});

export default theme;