import {config} from "./ConfigService";

class FaqChatResponse {
    articles;
    responseStreamUrl;

    constructor({articles, responseStreamUrl}) {
        this.articles = articles;
        this.responseStreamUrl = responseStreamUrl;
    }
}

class FaqChatDataset {
    name;
    code;

    constructor({name, code}) {
        this.name = name;
        this.code = code;
    }
}

class FaqChatModel {
    name;
    code;

    constructor({name, code}) {
        this.name = name;
        this.code = code;
    }
}

class FaqApiService {
    // Helper function to fetch data from an API
    #fetchData = async (endpoint, options = {}) => {
        const response = await fetch(`${config.faqApiUrl}${endpoint}`, options);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    };

    async fetchModels() {
        const response = await this.#fetchData(`/models`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.models.map(model => new FaqChatModel(model));
    }

    async fetchDatasets() {
        const response = await this.#fetchData(`/datasets`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.datasets.map(dataset => new FaqChatDataset(dataset));
    }

    /**
     * @param {string} prompt
     * @param {string} dataset
     * @param {string} model
     * @returns {Promise<FaqChatResponse>}
     */
    async askQuestion(prompt, dataset, model) {
        const response = await this.#fetchData(`/generate-response`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({prompt, dataset, model}),
        });
        return new FaqChatResponse(response);
    }

    streamResponse(url, chunkCallback, errorCallback, endCallback) {
        const source = new EventSource(url, {withCredentials: true});

        source.addEventListener("output", (e) => {
            const chunk = e.data;
            chunkCallback(chunk);
        });

        source.addEventListener("error", (e) => {
            source.close();
            errorCallback(e.data);
        });

        source.addEventListener("done", (e) => {
            source.close();
            endCallback(e.data)
        });
    }
}

const instance = new FaqApiService();
export {instance as faqApiService}