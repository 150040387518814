import React from 'react';
import {Paper, Typography} from "@mui/material";

// Footer Component
const Footer = () => (
  <Paper style={{ padding: 16, textAlign: 'center' }} elevation={3}>
    <Typography variant="body2">© 2024 Mediahuis</Typography>
  </Paper>
);

export default Footer;
