import React from 'react';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import './styles/main.scss';
import {Box, CssBaseline} from "@mui/material";

// App Component
const App = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            minHeight="100vh"
        >
            <CssBaseline/>
            <Header/>
            <Box
                component="main"
                flex="1"
                display="flex"
                flexDirection="column"
            >
                <MainContent/>
            </Box>
            <Footer/>
        </Box>
    );
};

export default App;