import {Card, CardContent, CardHeader, Typography} from "@mui/material";
import React from "react";

const ChatResponse = ({chatResponse, isLoading}) => {
    return <>{(chatResponse || isLoading) &&
        <Card variant="outlined" sx={{textAlign: 'left', minHeight: "150px"}}>
            <CardHeader title="Antwoord:"/>

            <CardContent>
                {/* Loading Indicator for Chat Response */}
                {isLoading && <Typography>...</Typography>}

                {/* Chat Response */}
                {!isLoading && <Typography>{chatResponse}</Typography>}
            </CardContent>
        </Card>
    }</>
}
export default ChatResponse;