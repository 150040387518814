import React, {useState, useEffect} from 'react';
import styles from './MainContent.module.css';
import {faqApiService} from "../services/FaqApiService";
import Grid from '@mui/material/Grid2';
import SendIcon from '@mui/icons-material/Send';
import {
    Card,
    Container,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import RelatedArticles from "./RelatedArticles";
import ChatResponse from "./ChatResponse";

function MainContent() {
    // State for datasets, models, selected dataset, selected model, user input, FAQ articles, chat response, and loading states
    const [datasets, setDatasets] = useState([]);
    const [models, setModels] = useState([]);
    const [selectedDataset, setSelectedDataset] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [userInput, setUserInput] = useState('Wat is het verschil tussen de nieuwsapp en de krantenapp?');
    const [faqArticles, setFaqArticles] = useState([]);
    const [chatResponse, setChatResponse] = useState('');
    const [isLoadingFAQ, setIsLoadingFAQ] = useState(false);
    const [isLoadingChat, setIsLoadingChat] = useState(false);

    // Fetch datasets and models on component mount
    useEffect(() => {
        const loadDatasets = async () => {
            try {
                const datasets = await faqApiService.fetchDatasets();
                setDatasets(datasets);
                setSelectedDataset(datasets[0]?.code || ''); // Set the first dataset as default
            } catch (error) {
                console.error('Failed to load datasets:', error);
            }
        };

        const loadModels = async () => {
            try {
                const models = await faqApiService.fetchModels();
                setModels(models);
                setSelectedModel(models[0]?.code || ''); // Set the first model as default
            } catch (error) {
                console.error('Failed to load models:', error);
            }
        };

        loadDatasets();
        loadModels();
    }, []);

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoadingFAQ(true);
        setIsLoadingChat(true);
        setChatResponse("");
        setFaqArticles([]);

        try {
            // Fetch chat response URL
            const responseData = await faqApiService.askQuestion(userInput, selectedDataset, selectedModel);

            setFaqArticles(responseData.articles);
            setIsLoadingFAQ(false);

            await streamChatResponse(responseData.responseStreamUrl);

        } catch (error) {
            console.error('Error during API calls:', error);
            setIsLoadingFAQ(false);
            setIsLoadingChat(false);
        }
    };

    // Fetch the actual chat response from the streaming URL
    const streamChatResponse = (url) => {
        setIsLoadingChat(true);

        let localChatResponse = "";
        let isInsideControlToken = false;
        let controlTokenBuffer = '';
        faqApiService.streamResponse(url, (chunk) => {
            if (isLoadingChat) {
                setIsLoadingChat(false);
            }
            console.log("Partial response received", chunk)

            for (let i = 0; i < chunk.length; i++) {
                const char = chunk[i];

                if (char === '<') {
                    isInsideControlToken = true;
                    controlTokenBuffer = '<';
                } else if (isInsideControlToken) {
                    controlTokenBuffer += char;

                    if (char === '>') {
                        isInsideControlToken = false;

                        // Check if it's a control token
                        if (controlTokenBuffer.includes('|')) {
                            controlTokenBuffer = '';  // Reset the buffer since we discard the control token
                        } else {
                            // It wasn't a control token, so add the buffer to the response
                            localChatResponse += controlTokenBuffer;
                            controlTokenBuffer = '';
                        }
                    }
                } else {
                    localChatResponse += char;
                }
            }
            setChatResponse(localChatResponse);
        }, (error) => {
            if (isLoadingChat) {
                setIsLoadingChat(false);
            }

            localChatResponse += "\n\n" + error;
            setChatResponse(localChatResponse);

        }, (chunk) => {
            console.log("done", chunk);
        });
        setIsLoadingChat(false);
    }

    const handleDatasetChange = (event) => {
        setSelectedDataset(event.target.value);
    };
    const handleModelChange = (event) => {
        setSelectedModel(event.target.value);
    };
    return (<Container>
        <Stack spacing={2}>
            <Card>
                <Grid container rowSpacing={1} columnSpacing={1}>
                    <Grid size={12} container padding={1}>
                        <TextField
                            sx={{width: "100%"}}
                            id="user-prompt"
                            placeholder="Stel je vraag..."
                            style={{height: 48}}
                            autoComplete="off"
                            multiline
                            variant="standard"
                            minRows={2}
                            maxRows={2}
                            className={styles.textarea}
                            value={userInput}
                            onChange={(e) => setUserInput(e.target.value)}
                        />
                    </Grid>
                    <Grid size={12} container padding={1}>
                        <Stack direction="row" spacing={2} sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%"
                        }}>
                            <Stack direction="row" spacing={2} sx={{
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <Select
                                    sx={{paddingBottom: 0}}
                                    size="small"
                                    id="dataset"
                                    label="Brand"
                                    value={selectedDataset}
                                    variant="standard"
                                    onChange={handleDatasetChange}
                                    disableUnderline={true}
                                >
                                    {datasets.map((dataset, index) => (<MenuItem key={index} value={dataset.code}>
                                        {dataset.name}
                                    </MenuItem>))}
                                </Select>
                                <Select
                                    sx={{padding: 0}}
                                    size="small"
                                    id="model"
                                    label="Model"
                                    value={selectedModel}
                                    variant="standard"
                                    onChange={handleModelChange}
                                    disableUnderline={true}
                                >
                                    {models.map((model, index) => <MenuItem key={index} value={model.code}>
                                        {model.name}
                                    </MenuItem>)}
                                </Select>
                            </Stack>
                            <IconButton loading={isLoadingChat || isLoadingFAQ} aria-label="ask-question" size="medium"
                                        color="primary"
                                        disabled={userInput?.length === 0}
                                        onClick={e => handleSubmit(e)}>
                                <SendIcon fontSize="inherit"/>
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Card>

            <ChatResponse isLoading={isLoadingChat} chatResponse={chatResponse}/>
            <RelatedArticles isLoading={isLoadingFAQ} articles={faqArticles}/>
        </Stack>
    </Container>);
}

export default MainContent;
