import React from 'react';
import {AppBar, Toolbar, Typography} from "@mui/material";

// Header Component
const Header = () => (
  <AppBar position="static">
    <Toolbar>
      <Typography variant="h6">Freddie</Typography>
    </Toolbar>
  </AppBar>
);
export default Header;